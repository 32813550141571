import React, { Fragment, useState, useEffect } from "react"
import FormatCurrency from "../utils/format-currency"

export default () => {
  const [formCalculator, setFormCalculator] = useState({
    valor_propiedad: null,
    monto_financiar: null,
    interes: 2.5,
    plazo: 20,
  })

  const [alert, setAlert] = useState(null)

  const [resultado, setResultado] = useState({ pie: 0, dividendo: 0 })

  const calculateCredit = e => {
    e.preventDefault()
    setAlert(null)
    setResultado({ pie: 0, dividendo: 0 })

    let interesMensual = parseFloat(formCalculator.interes)
    const valorFinanciar = parseFloat(formCalculator.valor_propiedad)
    const capital = parseFloat(formCalculator.monto_financiar)

    if (
      (!(valorFinanciar * 0.1 < capital) && capital < valorFinanciar * 0.9) ||
      valorFinanciar < capital
    ) {
      setAlert(
        "El monto a financiar debe estar entre el 10% y el 100% del valor de la propiedad"
      )
      return
    }

    const pieAprox = valorFinanciar - capital
    const tiempo = formCalculator.plazo * 12
    interesMensual = interesMensual / 12 / 100
    const resultado =
      capital *
      (((1 + interesMensual) ** tiempo * interesMensual) /
        ((1 + interesMensual) ** tiempo - 1))
    console.log(interesMensual)
    setResultado({
      dividendo: Math.round(resultado * 100) / 100,
      pie: Math.round(pieAprox * 100) / 100,
    })
  }

  const handleInputChange = ({ target }) => {
    console.log(target)
    setFormCalculator({ ...formCalculator, [target.name]: target.value })
  }

  useEffect(() => {
    console.log(formCalculator)
  })

  return (
    <Fragment>
      <section className="widget advance-search">
        <h4 className="title search-heading">Calcula tu crédito</h4>
        <div className="as-form-wrap">
          <form
            className="advance-search-form clearfix"
            onSubmit={calculateCredit}
          >
            <div className="option-bar">
              <label>Valor de la propiedad (UF)</label>
              <input
                name="valor_propiedad"
                type="number"
                placeholder="Ej: 7200"
                value={formCalculator.valor_propiedad}
                onChange={handleInputChange}
                required
              ></input>
              <label>Monto a financiar (UF)</label>
              <input
                name="monto_financiar"
                type="number"
                value={formCalculator.monto_financiar}
                placeholder="Ej: 2000"
                onChange={handleInputChange}
                required
              ></input>
              <label>Interés</label>
              <input
                name="interes"
                type="number"
                placeholder="Ej: 2.5"
                value={formCalculator.interes}
                onChange={handleInputChange}
                required
              ></input>
              <label>Años</label>
              <span className="selectwrap">
                <select
                  required
                  name="plazo"
                  id="select-status"
                  className="search-select"
                  onChange={handleInputChange}
                >
                  <option value="5">5</option>
                  <option value="8">8</option>
                  <option value="12">12</option>
                  <option value="15">15</option>
                  <option value="20" selected>
                    20
                  </option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                </select>
              </span>
              <input
                type="submit"
                value="Calcular"
                className="real-btn btn btn-block"
              />
            </div>
          </form>
          {alert && <div class="alert alert-danger">{alert}</div>}
          {resultado.pie > 0 && resultado.dividendo > 0 && (
            <div
              className="alert alert-success"
              style={{ textAlign: "center" }}
            >
              Pie aproximado
              <br />
              <b>UF {FormatCurrency("UF", resultado.pie)}</b>
              <br />
              Dividendo aproximado
              <br />
              <b>UF{resultado.dividendo}</b>
              <br />
            </div>
          )}
        </div>
      </section>
    </Fragment>
  )
}
