import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import FilterProperties from "../components/properties/FilterProperties"
import FeaturedProperties from "../components/properties/FeaturedProperties"
import Calculator from "../components/calculator"

import imagen1 from "../assets/images/temp-images/remate1.jpg"
import imagen2 from "../assets/images/temp-images/remate2.jpg"
import imagen3 from "../assets/images/temp-images/remate3.jpg"
import { Link } from "gatsby"

export default () => {
  const [pathname, setPathname] = useState("")
  useEffect(() => {
    setPathname(window.location.href)
  }, [])
  return (
    <Layout>
      <SEO
        title={`Propiedades en remate, vendemos su propiedad`}
        pathname={pathname}
      />
      <BannerTop title="Propiedades en remate, vendemos su propiedad" />
      <div class="container contents single">
        <div class="row">
          <div class="span9 main-wrap">
            <div class="main">
              <div
                class="inner-wrapper"
                style={{ padding: "10px 30px 10px 20px" }}
              >
                <div className="row-fluid">
                  <div className="span6">
                    <img src={imagen1} className="img-remate no-responsive " />
                  </div>
                  <div className="span6">
                    <div class=" container-fluid">
                      <br />
                      <h1 style={{ textAlign: "justify" }}>
                        Protegemos y vendemos su casa o departamento, antes que
                        el Banco la REMATE
                      </h1>
                      <br />
                      <h5 style={{ textAlign: "justify" }}>
                        Lo ayudamos a VENDER SU PROPIEDAD en tiempo record para
                        proteger su capital, si el banco remata, quedará con
                        deuda y de brazos cruzados, nosotros lo ayudamos a
                        vender y quédese con capital para volver a empezar.
                      </h5>
                      <br />
                      <br />
                      <h4 style={{ textAlign: "justify" }}>
                      ¿Sabía usted que, si su propiedad es rematada por el banco, perderá más del 50% de su capital?
                      </h4>
                      <br />
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        Por esa y otras razones hemos decidido ser un nexo de
                        solución entre nuestros clientes, el sistema bancario y
                        legal. Para eso cobramos desde el 2% del valor de venta
                        de la propiedad al vendedor como pago de nuestros
                        honorarios, además lo podemos asesorar legal y
                        comercialmente para alcanzar un mejor precio de oferta
                        de mercado, logrando así vender su propiedad en tiempo
                        record!, de esta manera podrá pagar sus deudas y quedar
                        con un capital de reinicio comercial.
                      </p>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        No pierda tiempo valioso en conocer el precio de mercado
                        o si quiere vender el inmueble al mejor precio, nuestros
                        expertos inmobiliarios estarán encantados de ayudarle
                        con una valoración gratuita y sin compromiso.
                      </p>
                    </div>
                    <br />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">
                    <div class=" container-fluid">
                      <br />
                      <br />
                      <h3 style={{ textAlign: "justify" }}>
                        Más de 17 años de experiencia comprobada en asesoría
                        legal e Inmobiliaria
                      </h3>
                      <h5 style={{ textAlign: "justify" }}>
                        ¿Está atrasado en 3 o más cuotas de su crédito
                        hipotecario?
                      </h5>
                      <h5 style={{ textAlign: "justify" }}>
                        ¿Se quedó sin trabajo o no podrá seguir pagando?
                      </h5>
                      <h5 style={{ textAlign: "justify" }}>
                        ¿Le avisaron que rematarán su propiedad?...
                      </h5>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        En caso de un juicio ejecutivo, el banco hará efectiva
                        la cláusula de aceleración y exigirá el pago del valor
                        total del crédito hipotecario. Una vez notificada la
                        demanda, tendrá 10 días para pagar el saldo adeudado.
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        Somos expertos negociadores representando a nuestros
                        clientes ante bancos y el poder judicial, no pierda
                        tiempo, contáctenos y vendamos su propiedad antes que el
                        banco la remate, quede sin capital y peor aún con
                        deudas.
                      </p>
                      <br />
                      <h5>Servicios legales y de corretaje</h5>
                      <br />
                      <h5>Bancos acreedores</h5>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        Mediante nuestro equipo legal y comercial nos acercamos
                        a negociar con los bancos el tiempo requerido para poder
                        vender su propiedad.
                      </p>
                      <br />
                      <h5>Precio Oferta</h5>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        Venderemos su propiedad en el menor tiempo posible,
                        brindando un mejor precio de venta y más elevado por
                        sobre el precio de remate judicial, permitiendo en
                        muchos casos pagar las deudas y quedarse con un flujo
                        para volver a comenzar.
                      </p>
                    </div>
                  </div>
                  <div className="span6">
                    <img src={imagen2} className="img-remate no-responsive " />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span6">
                    <img src={imagen3} className="img-remate " />
                  </div>
                  <div className="span6">
                    <div class=" container-fluid">
                      <br />
                      <br />
                      <h3>Proceso Clave</h3>
                      <br />
                      <h6>1. ESTRATEGIA</h6>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        Análisis de la situación financiera, etapa de la
                        morosidad, tasación de la propiedad. Para esto es vital
                        la transparencia del cliente. Necesitamos absoluta
                        claridad, para la elaboración de la estrategia comercial
                        y legal para detener el remate.
                      </p>
                      <br />
                      <h6>2. EVALUACIÓN DE ALTERNATIVAS</h6>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        Definimos junto al cliente las diversas alternativas,
                        etapas del proceso, costos y plan de acción inmediato.
                      </p>

                      <br />
                      <h6>3. NEGOCIACIÓN</h6>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        Luego de conocer con total claridad la situación
                        comercial de nuestros clientes , con una estrategia
                        robusta, negociamos tiempo con los bancos involucrados y
                        generamos instancias de salvaguarda ante la ley, esto
                        nos permite tener mayor tiempo de obtener un mejor
                        precio de venta de su propiedad.{" "}
                      </p>
                      <br />
                      <h6>4. VENTA</h6>
                      <br />
                      <p style={{ textAlign: "justify" }}>
                        Manejamos una cartera importante de inversionistas
                        quienes pueden comprar su propiedad en un modelo de
                        corto plazo y mejor precio de venta. Obtenemos mayores
                        ganancias que la que se obtiene tras un remate judicial.
                      </p>
                      <br />
                      <span style={{ display: "inline-flex" }}>
                        <text
                          style={{
                            color: "#e3712c",
                            fontSize: "22px",
                            fontWeight: "700",
                            padding: "7px",
                            textAlign: "center",
                          }}
                        >
                          ¿NECESITAS AYUDA?
                        </text>
                        <Link
                          to="/contacto"
                          className="real-btn"
                          style={{
                            borderRadius: "45.5px",
                          }}
                        >
                          CONTACTANOS
                        </Link>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                {/*   <article class="page type-page clearfix">
                  <StaticQuery
                    query={graphql`
                      query {
                        file(relativePath: { eq: "fondo1.jpg" }) {
                          childImageSharp {
                            fluid(quality: 90, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    `}
                    render={data => {
                      // Set ImageData.
                      const imageData = data.file.childImageSharp.fluid
                      return (
                        <BackgroundImage
                          Tag="section"
                          className="page-head"
                          fluid={imageData}
                          style={{ height: "300px" }}
                          backgroundColor={`#040e18`}
                        ></BackgroundImage>
                      )
                    }}
                  />
                  <hr />
                  <h3>Venda o Arriende su inmueble con nosotros!</h3>
                   <p style={{ textAlign: "justify" }}>
                    Lo pondremos en contacto con un experto local en su zona,
                    que le asesorará en determinar el precio de mercado exacto
                    de su&nbsp;propiedad y que conoce al comprador apropiado.
                  </p>
                  <hr />
                  <h3>Conocemos el valor de su inmueble!</h3>
                   <p style={{ textAlign: "justify" }}>
                    ¿Sabe cuál es el valor de su propiedad?, conocemos frases
                    como: no tengo apuro…, mi vecino vendió una casa parecida
                    en…
                  </p>
                   <p style={{ textAlign: "justify" }}>
                    No pierda tiempo valioso en conocer el precio de mercado o
                    si quiere vender el inmueble al mejor precio, nuestros
                    expertos inmobiliarios estarán encantados de ayudarle con
                    una valoración gratuita y sin compromiso.
                  </p>
                  <hr />
                  <h3>Etapas de nuestro Servicio</h3>
                  <div class="seller-button">
                    <div class="ev-btn-caption-container">
                      <div class="ev-btn-caption">
                        <h5>1. Asesoramiento profesional e individual</h5>
                        <h5>
                          2. Estimación competente del precio de venta o
                          arriendo
                        </h5>
                        <h5>
                          3.- Dedicación exclusiva de un Agente Inmobiliario
                        </h5>
                        <h5>
                          4. Preparación de ficha técnica, legal y fotografías
                          de la propiedad
                        </h5>
                        <h5>
                          5.&nbsp;Inversión plan de marketing individual
                          financiado por Agente Inmobiliario
                        </h5>
                        <h5>
                          6. Planeamiento óptimo de las visitas a través de
                          órdenes electrónicas supervisadas
                        </h5>
                        <h5>
                          7. Informes regulares del estado de venta o arriendo
                        </h5>
                        <h5>
                          8. Negociación contractual profesional y apoyo legal
                          permanente
                        </h5>
                        <h5>9. Servicio post venta integral</h5>
                        <h5></h5>
                        <hr />
                         <p style={{ textAlign: "justify" }}>
                          Agente Inmobiliario más de 13 años de experiencia
                          comprobada.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </article>
              */}{" "}
              </div>
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
              <FeaturedProperties quantity={2} />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}
